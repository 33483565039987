import * as React from "react";
import { useLocation } from "react-router-dom";
import { FaqProps } from "./typings";
import { observer, inject } from "mobx-react";
import { LegacyFrequentlyAskedQuestionsModule as LegacyFAQ } from "./legacy-flex/Faq";
import { FrequentlyAskedQuestionsModule as SharedUIFAQ } from "./shared-ui/Faq";

/**
 * Frequently asked questions module that allows users to gather
 * more information on their desired destinations
 */
export const Faq = inject(
  "markdown",
  "flexModuleModelStore",
  "uriContext"
)(
  observer((props: FaqProps) => {
    const { flexModuleModelStore, context, uriContext: uriContextStore, markdown, templateComponent } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const showDebugView = queryParams.get("debug") === "true";
    const isBrandExpedia = context.site.brand === "expedia";
    const isHotelInformation = context.searchContext.pageType === "Hotel-Information";
    const isClientSideOnly = templateComponent?.config.clientSideOnly ?? false;

    return isHotelInformation && isBrandExpedia ? (
      <section>
        <SharedUIFAQ context={context} templateComponent={templateComponent} uriContext={uriContextStore} />
      </section>
    ) : isClientSideOnly ? (
      <SharedUIFAQ context={context} templateComponent={templateComponent} uriContext={uriContextStore} />
    ) : (
      <div id="FAQ">
        <LegacyFAQ
          context={context}
          templateComponent={templateComponent}
          flexModuleModelStore={flexModuleModelStore}
          markdown={markdown}
        />
        {showDebugView && (
          <SharedUIFAQ context={context} templateComponent={templateComponent} uriContext={uriContextStore} />
        )}
      </div>
    );
  })
);

export default Faq;
